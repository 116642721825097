<template src="./WelcomeHome.html"></template>

<script>
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import { songs } from "@/utils/constants";

export default {
  name: "welcomeHome",
  components: { ArrobaMedellinLogo },
  data() {
    return {
      songs,
      emailsSelect: [],
      emails: []
    };
  },
  methods: {
    async goToHome() {
      this.$router.push({ path: `/` });
    },
  },
};
</script>

<style lang="scss" src="./WelcomeHome.scss" scoped></style>
